<template>
      <div class="image">

   <Gallery :images="images" />


<section class="gallerie">
<template>
  <v-row>
    <v-col cols="8">

      <div
          class="shadow-sm p-3 mb-5 bg-white rounded"
          style="margin-left:30px"
        >
    
<v-card
  elevation="8"

>
  <v-card-text>
    <p><b>Adres: </b>  {{data.adres}}  </p>

    <p><b>Prijs: </b>  {{ data.prijs }}  </p>

    <p><b>Vierkante meter:  </b> {{ data.meters }} vierkante meters </p>
        <p><b>Perceel oppervlakte:  </b> {{ data.Perceel }} vierkante meters </p>
   <p><b>
                Aangeboden sinds: </b> 
                {{ new Date(data.createDate).toLocaleDateString() }}
              </p>
                </v-card-text>
</v-card>
      </div>
    </v-col>

    <v-col cols="4">
 <v-card
    class="mx-auto"
    max-width="400"
    style="margin-bottom:30px"
  >
    <v-img
      class="white--text align-end"
      height="200px"
      src="@/assets/logo.png"
    >
     
    </v-img>

    <v-card-subtitle class="pb-0">
    <h3>  Neem contact op </h3>
    </v-card-subtitle>

    <v-card-text class="text--primary">
   
    t: 085 130 4450<br>
    @: info @jouwbedrijfsmakelaar.nl

    </v-card-text>

    <v-card-actions>
      <v-btn
        color="orange"
      text
      >
 <a class="button" href="mailto:info@jouwbedrijfsmakelaar.nl"> Neem contact op </a>
      </v-btn>

   
    </v-card-actions>
  </v-card>
    </v-col>

  </v-row>
</template>

</section>


      </div>
</template>


<script>
import Gallery from "vue-cover-gallery";
import axios from "axios";
export default {
  name: "Map",
  components: {

    Gallery,
  },
  data() {
    return {
          slide: 0,
           sliding: null,
     data: {
        images: [],
      },
      sliding: null,
      currentUrl: "",
      statusInfo: [],
      bieden: "",
      slide: 0,
      loading: false,
   
      sliding: null,
      descExpanded: false,
    };
  },

  created() {
    this.currentUrl = window.location.href;
  },
      images() {
      return this.data.images.map((image) => ({
        href: `https://back-end.mcmakelaardij.nl/static/${image.filename}`,
      }));
    },

  
  
  computed: {
    hasImages() {
      return this.data.images.length > 0;
    },
    description() {
      return this.descExpanded
        ? this.building.bijzonderheden
        : this.building.bijzonderheden.substring(0, 100);
    },
       images() {
      return this.data.images.map((image) => ({
        href: `https://back-end.mcmakelaardij.nl/static/${image.filename}`,
      }));
    },

  },

  async mounted() {
    this.loading = true;
    await this.getData();
    await this.getImageUrl();
    this.loading = false;
  },

  methods: {
    async getData() {
      const response = await axios.get(
        `https://back-end.mcmakelaardij.nl/api/house/image/${this.$route.params.id}`
      );
      this.data = response.data;
    },
     getImageUrl(path) {
          return `https://back-end.mcmakelaardij.nl/static/${path}`;
        
      }
      
  },
};
</script>

<style>
.img-left {
  height: 500px !important;
}
.img-right {
  height: 250px !important;
}
.image{
  margin-top:20px
}
</style>