
<template>
  <SingleBuilding />
</template>

<script>
import SingleBuilding from "@/components/view/SingleBuilding";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    SingleBuilding,
  },
  metaInfo: {
    title: "Jouwbedrijfsmakelaar | Bedrijfspand informatie",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    background-color: white;
    z-index: 100;
    height: calc(100vh - 75px);
    overflow-y: auto;
    margin-top: -10px;
    border-right: 1px solid black;
  }
}
</style>

<style scoped>
.close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
}
.filter {
  margin-bottom: 25px;
}

</style>